import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import * as Yup from "yup"
import { map } from "lodash"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"

import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import mini card widgets
import MiniCards from "./mini-card"

//Import Images
import profile1 from "assets/images/users/avatar-6.jpg"
import bg from "assets/images/profile-img.png"
// import charts
import {
  getUserProfile,
  updateBusinessPartner,
  updateProfil,
} from "store/actions"
import moment from "moment"
import ApexRevenue from "pages/Admins/ApexRevenue"
import ReactSwitch from "react-switch"
import { useFormik } from "formik"

const PartnerProfile = props => {
  //meta title
  document.title = "Mazaya"
  const params = useParams()
  const id = params.id
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")

  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Pairring users"),
      iconClass: "bx-check-circle",
      text: "125",
    },
    {
      title: props.t("Total Purchasing"),
      iconClass: "bx-package",
      text: "$36,524",
    },
  ])

  const { userProfile, success, error } = useSelector(state => state.users)

  useEffect(() => {
    if (id) {
      dispatch(getUserProfile(id))
    }
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      salesCommission: (userProfile.salesCommission && userProfile.salesCommission) || "",
    },
    validationSchema: Yup.object({
      salesCommission: Yup.number()
        .min(-1,(props.t ("Sales Commission must be equal or grearter than 0")))
        .required(props.t("Please enter Sales Commission")),
    }),
    onSubmit: values => {
      const updatedSalesCommission = {
        id: userProfile.id,
        salesCommission: values.salesCommission,
      }
      // update order
      dispatch(updateBusinessPartner(updatedSalesCommission))
    },
  })

  const ChangeStatus = status => {
    // console.log("status",status)
    if (status) {
      // console.log("status if true",status)
      dispatch(updateProfil({ id: userProfile.id, status: "active" }))
      dispatch(updateBusinessPartner({ id: userProfile.id, status: "active" }))
    } else {
      // console.log("status if false",status)
      dispatch(updateProfil({ id: userProfile.id, status: "inactive" }))
      dispatch(
        updateBusinessPartner({ id: userProfile.id, status: "inactive" })
      )
    }
  }
  const IsSales = isSalesPartner => {
    // console.log("isSalesPartner",isSalesPartner)
    if (isSalesPartner) {
      // console.log("isSalesPartner if true",isSalesPartner)
      dispatch(updateProfil({ id: userProfile.id, isSalesPartner: true }))
      dispatch(
        updateBusinessPartner({ id: userProfile.id, isSalesPartner: true })
      )
    } else {
      // console.log("isSalesPartner if false",isSalesPartner)
      dispatch(updateProfil({ id: userProfile.id, isSalesPartner: false }))
      dispatch(
        updateBusinessPartner({ id: userProfile.id, isSalesPartner: false })
      )
    }
  }
  // useEffect(() => {
    
  //   // console.log("++++++",success)
  //   console.log("success", success)

  // if (success == "UPDATE") {
  //     setSuccessModal(true)
  //     setMessage(" edited successfully !")
  //     dispatch(onReset())
  //   }
  // }, [success])
  // console.log("userProfile?.isSalesPartner", userProfile?.isSalesPartner)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("Partner")}
            breadcrumbItem={props.t("Profile")}
          />

          <Row>
            <Col xl="6">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        {/* <h5 className="text-primary">Welcome!</h5> */}
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={bg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="12">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={userProfile.image_url || profile1}
                          alt=""
                          className="img-thumbnail font-size-10  rounded-circle"
                        />
                      </div>
                      <br />
                      <br />
                      <Row>
                        <Col xl="6">
                          <h5 className="font-size-15 text-truncate mb-3">
                            {userProfile.firstname +
                              " " +
                              userProfile.lastname +
                              "(" +
                              userProfile.username +
                              ")"}
                          </h5>
                          <p className="text-muted font-size-15 mb-0 text-truncate">
                            <p>
                              referral code:{" "}
                              {userProfile.code || props.t("Not provided")}
                            </p>
                          </p>
                          <p className="text-muted font-size-15 mb-0 text-truncate">
                            {userProfile.accountType}
                          </p>
                          <br></br>
                        <h5 className="font-size-15 text-truncate">
                          {props.t("Sales Partner")}
                        </h5>

                        <ReactSwitch
                          onColor="#FF9E00"
                          onChange={IsSales}
                          checked={userProfile?.isSalesPartner}
                        />
                      
                        </Col>
                        <Col xl="4">
                          <h5 className="font-size-15 text-truncate">
                            {props.t("Status")}
                          </h5>
                          <Col xl="3">
                            <ReactSwitch
                              onColor="#34c38f"
                              onChange={ChangeStatus}
                              checked={
                                userProfile.status == "active" ? true : false
                              }
                            />
                          </Col>
                          <br></br>
                          <br></br>
                          <Col xl="12">
                            {userProfile?.isSalesPartner && (
                              <Row form>
                                <Col xl="8">
                                  <Label className="form-label">
                                    {props.t("Sales Commission")}
                                  </Label>
                                </Col>
                                <Col xl="9">
                                  <div className="mb-3">
                                    <Input
                                      name="salesCommission"
                                      type="number"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.salesCommission || ""}
                                      invalid={
                                        validation.touched.salesCommission &&
                                        validation.errors.salesCommission
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.salesCommission &&
                                    validation.errors.salesCommission ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.salesCommission}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xl="3">
                                  <Button
                                    onClick={validation.submitForm}
                                    type="primary"
                                  >
                                    {props.t("Save")}
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Col>
                      </Row>
                  
                      
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Personal Information")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{props.t("Full Name")} :</th>
                          <td>
                            {userProfile.firstname + " " + userProfile.lastname}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Status")} :</th>
                          <td>
                            {userProfile.status || props.t("Not provided")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Type")} :</th>
                          <td>
                            {userProfile.accountType || props.t("Not provided")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Mobile")} :</th>
                          <td>
                            {userProfile.phone || props.t("Not provided")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("E-mail")} :</th>
                          <td>{userProfile.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Joined")} :</th>
                          <td>
                            {moment(new Date(userProfile.createdAt)).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="6">
              <Row className="justify-content-end">
                <Col xl="5">
                  <div className="mb-2">
                    <Input
                      type="text"
                      placeholder={props.t("Search by username")}
                      //onChange={validation.handleChange}
                    />
                  </div>
                </Col>
                <Col xl="1">
                  <Button
                    className="btn btn-primary save-user"
                    //onClick={validation.submitForm}
                  >
                    {props.t("Search")}
                  </Button>
                </Col>
              </Row>
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t("Peering")}</CardTitle>
                  <div id="revenue-chart">
                    <ApexRevenue />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  {props.t("Contact Details")}
                </CardTitle>

                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">{props.t("Address")} :</th>
                      <td>{userProfile.address || props.t("Not provided")}</td>
                    </tr>
                    <tr>
                      <th scope="row">{props.t("Country")} :</th>
                      <td>
                        {" "}
                        {(currentLanguage == "ar" &&
                          userProfile.country?.nameAr) ||
                          (currentLanguage == "en" &&
                            userProfile.country?.nameEn) ||
                          (currentLanguage == "fr" &&
                            userProfile.country?.nameFr) ||
                          props.t("Not provided")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{props.t("Governorate")} :</th>
                      <td>
                        {" "}
                        {(currentLanguage == "ar" &&
                          userProfile.governorate?.nameAr) ||
                          (currentLanguage == "en" &&
                            userProfile.governorate?.nameEn) ||
                          (currentLanguage == "fr" &&
                            userProfile.governorate?.nameFr) ||
                          props.t("Not provided")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{props.t("City")} :</th>
                      <td>
                        {(currentLanguage == "ar" &&
                          userProfile.city?.nameAr) ||
                          (currentLanguage == "en" &&
                            userProfile.city?.nameEn) ||
                          (currentLanguage == "fr" &&
                            userProfile.city?.nameFr) ||
                          props.t("Not provided")}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{props.t("Parrain")} :</th>
                      <td>
                        {userProfile.parrain?.username ||
                          props.t("Not provided")}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PartnerProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(withTranslation()(PartnerProfile))
