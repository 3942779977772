import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty, update } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import { getServices, resetServiceMessages, updateService } from "store/actions"

import { EnName, ArabicName, UserName } from "./ServiceCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Container,
} from "reactstrap"
import ServiceModal from "./ServiceModal"
import { withTranslation } from "react-i18next"
import ShowModal from "./ShowModal"
import { checkPermission } from "helpers/functions"
import SuccessModal from "components/Common/SuccessModal"

function Service(props) {
  const {
    services,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading,
  } = useSelector(state => state.services)

  //meta title
  document.title = "Mazaya"

  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [servicesList, setServicesList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [service, setService] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })

  const handleCustomerClick = arg => {
    const service = arg

    setService({
      id: service.id,
      nameEn: service.nameEn,
      nameFr: service.nameFr,
      nameAr: service.nameAr,
    })

    setIsEdit(true)
    toggle()
  }

  // Countries Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("English Name"),
        accessor: "nameEn",
        disableFilters: true,
        Cell: cellProps => {
          return <EnName {...cellProps} />
        },
      },

      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const serviceData = cellProps.row.original
                  handleCustomerClick(serviceData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const serviceData = cellProps.row.original
                  onClickDelete(serviceData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setService(null)
    } else {
      setModal(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = service => {
    setService(service)
    setDeleteModal(true)
  }

  const handleDeleteGovernorate = () => {
    if (service.id) {
      dispatch(updateService({ id: service.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getServices())
    }
  }

  useEffect(() => {
    {
      dispatch(getServices(filter))
    }
  }, [dispatch, filter])

  useEffect(() => {
    setServicesList(services)
  }, [services])

  useEffect(() => {
    if (!isEmpty(services)) {
      setServicesList(services)
    }
  }, [services])

  const handleGovernorateClicks = () => {
    setServicesList("")
    setIsEdit(false)
    toggle()
  }

  useEffect(() => {
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Service created successfully !")
      dispatch(resetServiceMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Successfully !")
      dispatch(resetServiceMessages())
    }
  }, [success])

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGovernorate}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("MDM")}
            breadcrumbItem={props.t("Service List")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={services}
                    isAddCustList
                    handelAddClick={handleGovernorateClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                  <ServiceModal
                    modal={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    service={service}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
Service.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Service)
