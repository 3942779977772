import {
    CARD_MESSAGES_RESET,
    RESET_CARD_MESSAGES_SUCCESS,
    CARD_MESSAGES_SUCCESS,
    CREATE_CARD_FAILED,
    CREATE_CARD_REQUEST,
    CREATE_CARD_SUCCESS,
    DELETE_CARD_FAILED,
    DELETE_CARD_REQUEST,
    DELETE_CARD_SUCCESS,
    GET_CARD_BY_NAME_FAILED,
    GET_CARD_BY_NAME_REQUEST,
    GET_CARD_BY_NAME_SUCCESS,
    GET_CARD_FAILED,
    GET_CARD_REQUEST,
    GET_CARD_SUCCESS,
    UPDATE_CARD_FAILED,
    UPDATE_CARD_REQUEST,
    UPDATE_CARD_SUCCESS,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    cardData: [],
    success: "",
    error: "",
    nextPage: null,
    previousPage: null,
    limit: 0,
    total:0,
    currentPage: 1
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_CARD_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_CARD_SUCCESS:
        const {data, ...filters} = action.payload
        return {
          ...state,
          cardData: [...data],
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_CARD_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_CARD_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_CARD_BY_NAME_SUCCESS:
        return {
          ...state,
          cardData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_CARD_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_CARD_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_CARD_SUCCESS:
        return {
          ...state,
          cardData: [...state.cardData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_CARD_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_CARD_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_CARD_SUCCESS:
        return {
          ...state,
          cardData: state.cardData.filter(
            card => card.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_CARD_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_CARD_REQUEST:
        return {
          ...state,
          loading: true,
          success: "UPDATE",
          error: "",
        }
      case UPDATE_CARD_SUCCESS:
        return {
          ...state,
          cardData: state.cardData.map(card =>
            card.id.toString() === action.payload.id.toString()
              ? { card, ...action.payload }
              : card
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_CARD_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case CARD_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_CARD_MESSAGES_SUCCESS:
        return {
          ...state,
          error: null,
          success: null
        }
      default:
        return state
    }
  }
  
  export default reducer
  