import React, { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import {
  createRetailer,
  getMDMBrand,
  getMDMCategories,
  getMDMCities,
  getMDMCountries,
  getMDMGovernorates,
  updateRetailer,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { upload } from "helpers/api_helper"
import PlacesAutocomplete from "react-places-autocomplete"
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete"
import { withTranslation } from "react-i18next"

const ModalOffice = props => {
  const dispatch = useDispatch()
  const fileRef = useRef()
  const [loading, setLoading] = useState(false)
  const { modal, toggle, retailer, isEdit } = props
  const { categoriesData } = useSelector(state => state.categories)
  const { citiesData } = useSelector(state => state.cities)
  const { countriesData } = useSelector(state => state.countries)
  const { governoratesData } = useSelector(state => state.governorates)
  const { brandData } = useSelector(state => state.brands)
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
  const { user } = useSelector(state => state.Login)

  const [address, setAddress] = useState("")
  const [latLang, setLatLang] = useState("")
  const [location, setLocation] = useState({ lng: null, lat: null })

  useEffect(() => {
    // dispatch(getMDMCategories())
    dispatch(getMDMCities())
    dispatch(getMDMCountries())
    dispatch(getMDMGovernorates())
    dispatch(getMDMBrand())
  }, [])
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (retailer && retailer.name) || "",
      imageUrl: (retailer && retailer.imageUrl) || "",
      phone: (retailer && retailer.phone) || "",
      email: (retailer && retailer.email) || "",
      cityId: (retailer && retailer.cityId) || "",
      countryId: (retailer && retailer.countryId) || "",
      brandId: (retailer && retailer.brandId) || "",
      stateId: (retailer && retailer.stateId) || "",
      createdBy: (retailer && retailer.createdBy) || "",
      status: (retailer && retailer.status) || "",
      address: (retailer && retailer.address) || "",
      location:
        retailer?.location?.coordinates?.length > 0
          ? [
              retailer?.location?.coordinates[1],
              retailer?.location?.coordinates[0],
            ]
          : [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Name")),
      // lastname: Yup.string()
      //   .required(props.t("Please Enter Your Last Name"))
      //   .matches(props.t("^[a-zA-Z]+$", "only latin words")),
      phone: Yup.string().required(props.t("Please Enter Your Phone")),
      // shopName: Yup.string().required(props.t("Please Enter Your Shop Name")),
      // comission: Yup.string().required(props.t("Please Enter Your Comission")),
      email: Yup.string().required(props.t("Please Enter Your Email")),
      //countryId: Yup.string().required(props.t("Please Enter Your City")),
      //brandId: Yup.string().required("Please Enter Your brand"),
      brandId: Yup.string().required(props.t("Please Enter Your Brand")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedRetailer = {
          id: retailer.id,
          imageUrl: values.imageUrl || retailer.imageUrl,
          name: values.name,
          phone: values.phone,
          email: values.email,
          address: values.address,
          cityId: parseInt(values.cityId) || null,
          countryId: parseInt(values.countryId) || null,
          brandId: parseInt(values.brandId) || null,
          stateId: parseInt(values.stateId) || null,
          status: values.status || "inactive",
          location:
            retailer?.location?.coordinates?.length > 0
              ? {
                  type: "Point",
                  coordinates: [
                    retailer?.location?.coordinates[0],
                    retailer?.location?.coordinates[1],
                  ],
                }
              : {
                  type: "Point",
                  coordinates: [latLang.lat, latLang.lng],
                },
        }
        // update order
        dispatch(updateRetailer(updatedRetailer))

        validation.resetForm()
      } else {
        const newRetailer = {
          name: values["name"],
          imageUrl: values["imageUrl"],
          phone: values["phone"],
          email: values["email"],
          cityId: parseInt(values["cityId"]) || null,
          brandId: parseInt(values["brandId"]) || null,
          countryId: parseInt(values["countryId"]) || null,
          stateId: parseInt(values["stateId"]) || null,
          createdBy: user?.id || null,
          status: values["status"],
          location: {
            type: "Point",
            coordinates: [latLang.lat, latLang.lng],
          },
        }
        console.log("neww", newRetailer)
        // save new category
        dispatch(createRetailer(newRetailer))
        validation.resetForm()
      }
      toggle()
      console.log("values+++++++", values["imageUrl"])
    },
  })

  useEffect(() => {
    if (retailer) setAddress(retailer.address)
    else setAddress("")
  }, [retailer])

  const handelImage = event => {
    setLoading(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoading(false)
        validation.setFieldValue("imageUrl", path)
      })
      .catch(e => {
        setLoading(false)
        console.log(e.response)
      })
  }

  const handleBrandImage = async e => {
    validation.handleChange(e)
    let image = brandData.find(brand => brand.id == e.target.value)?.imageUrl
    console.log("image", image)
    validation.setFieldValue("imageUrl", image)
  }

  const handleFileUpload = e => {
    e.preventDefault()
    fileRef?.current?.click()
  }

  const resetFileHandler = e => {
    e.preventDefault()
    validation.setFieldValue("imageUrl", "")
  }

  const handleSelect = async value => {
    const results = await geocodeByAddress(value)
    const ll = await getLatLng(results[0])
    setAddress(value)
    setLatLang(ll)
    validation.setFieldValue("location", [ll.lat, ll.lat])

    validation.setFieldValue("address", results[0].formatted_address)
    setLocation({
      lat: results[0].geometry?.location?.lat(),
      lng: results[0].geometry?.location?.lng(),
      address,
    })
    console.log("address", results)
    console.log("ll", ll)
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit Store") : props.t("Add Store")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Retailer")}</Label>
                <Input
                  name="brandId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={e => handleBrandImage(e)}
                  onBlur={validation.handleBlur}
                  value={validation.values.brandId || ""}
                  invalid={
                    validation.touched.brandId && validation.errors.brandId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {brandData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name || "---"}
                    </option>
                  ))}
                </Input>
                <p className="card-title-desc font-size-11 ms-1 me-1">
                  {props.t(
                    "Selecting a brand wil automatically set your logo to the chosen brand image. You can change it with the below field."
                  )}
                </p>
                {validation.touched.brandId && validation.errors.brandId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.brandId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-5">
                <Label className="form-label">{props.t("Logo")}</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <button
                        className="btn btn-light w-100"
                        onClick={handleFileUpload}
                      >
                        {!loading &&
                          !validation.values.imageUrl &&
                          props.t("Upload Image")}
                        {loading && (
                          <Spinner className="text-center mt-1" size="sm" />
                        )}
                        {!loading &&
                          validation.values?.imageUrl?.split("/")[
                            validation.values?.imageUrl?.split("/").length - 1
                          ]}
                      </button>
                      <span
                        className="btn btn-info btn-icon sketch-picker ms-n4 mt-n2 rounded-circle"
                        onClick={resetFileHandler}
                      >
                        <i className="bx bx-x bx-xs"></i>
                      </span>
                      <Input
                        innerRef={fileRef}
                        name="imageUrl"
                        type="file"
                        hidden
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={handelImage}
                        onBlur={validation.handleBlur}
                        //value={convertToBlob(validation.values.image_url)}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {/* {!loading && validation.values.image_url && isEdit && */}
                      {/* (<i
                        className="bx bx-x bx-sm float-end me-3"
                        //type="button"
                        onClick={onRemove}
                      />) */}
                      {/* } */}
                    </div>
                  </div>
                </div>
                {validation.touched.logo && validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="name"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">{props.t("Phone")}</Label>
                <Input
                  name="phone"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">{props.t("Email")}</Label>
                <Input
                  name="email"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="row">
                <div className="col-4 mb-3">
                  <Label className="form-label">{props.t("Country")}</Label>
                  <Input
                    name="countryId"
                    type="select"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.countryId || ""}
                    invalid={
                      validation.touched.countryId &&
                      validation.errors.countryId
                        ? true
                        : false
                    }
                  >
                    <option key="empty" disabled></option>
                    {countriesData.map(item => (
                      <option key={item.id} value={item.id}>
                        {(currentLanguage == "ar" && item.nameAr) ||
                          (currentLanguage == "en" && item.nameEn) ||
                          (currentLanguage == "fr" && item.nameFr)}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.countryId &&
                  validation.errors.countryId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.countryId}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-4 mb-3">
                  <Label className="form-label">{props.t("Governorate")}</Label>
                  <Input
                    name="stateId"
                    type="select"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.stateId || ""}
                    invalid={
                      validation.touched.stateId && validation.errors.stateId
                        ? true
                        : false
                    }
                  >
                    <option key="empty" disabled></option>
                    {governoratesData
                      .filter(
                        item =>
                          item.parentId ==
                          (validation.values.countryId ||
                            validation.initialValues.countryId)
                      )
                      ?.map(item => (
                        <option key={item.id} value={item.id}>
                          {(currentLanguage == "ar" && item.nameAr) ||
                            (currentLanguage == "en" && item.nameEn) ||
                            (currentLanguage == "fr" && item.nameFr)}
                        </option>
                      ))}
                  </Input>
                  {validation.touched.stateId && validation.errors.stateId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.stateId}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-4 mb-3">
                  <Label className="form-label">{props.t("City")}</Label>
                  <Input
                    name="cityId"
                    type="select"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.cityId || ""}
                    invalid={
                      validation.touched.cityId && validation.errors.cityId
                        ? true
                        : false
                    }
                  >
                    <option key="empty" disabled></option>
                    {citiesData
                      .filter(
                        item =>
                          item.parentId ==
                          (validation.values.stateId ||
                            validation.initialValues.stateId)
                      )
                      ?.map(item => (
                        <option key={item.id} value={item.id}>
                          {(currentLanguage == "ar" && item.nameAr) ||
                            (currentLanguage == "en" && item.nameEn) ||
                            (currentLanguage == "fr" && item.nameFr)}
                        </option>
                      ))}
                  </Input>
                  {validation.touched.cityId && validation.errors.cityId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.cityId}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Address")}</Label>

                <PlacesAutocomplete
                  value={address}
                  name="address"
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div style={{ width: "100%", padding: "10" }}>
                      {" "}
                      <input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "location-search-input",
                          style: {
                            width: "100%",
                            boxSizing: "border-box",
                            padding: "10px",
                          },
                        })}
                      />
                      <div
                        className="autocomplete-dropdown-container"
                        style={{
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item"
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                                padding: "10px",
                                borderBottom: "1px solid #ddd",
                                width: "100%", // Assurez-vous que chaque suggestion occupe 100% de la largeur du conteneur
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                                padding: "10px",
                                borderBottom: "1px solid #ddd",
                                width: "100%", // Assurez-vous que chaque suggestion occupe 100% de la largeur du conteneur
                              }
                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                {validation.touched.address && validation.errors.address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.address}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Status")}</Label>
                <Input
                  name="status"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={e => validation.handleChange(e)}
                  onBlur={validation.handleBlur}
                  value={validation.values.status || ""}
                  invalid={
                    validation.touched.status && validation.errors.status
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Input>

                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">{props.t("Category")}</Label>
                <Input
                  type="select"
                  name="categoryId"
                  className="form-select"
                  onChange={e => {
                    validation.handleChange(e)
                    validation.setFieldValue(
                      "comission",
                      categoriesData.find(
                        category => category.id == e.target.value
                      )?.comission
                    )
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.categoryId || []}
                  invalid={
                    validation.touched.categoryId &&
                    validation.errors.categoryId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {categoriesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {(currentLanguage == "ar" && item.nameAr) ||
                        (currentLanguage == "en" && item.nameEn) ||
                        (currentLanguage == "fr" && item.nameFr)}
                    </option>
                  ))}
                </Input>
                {validation.touched.categoryId &&
                validation.errors.categoryId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.categoryId}
                  </FormFeedback>
                ) : null}
              </div> */}
              {/* <div className="mb-3">
                <Label className="form-label">{props.t("Commission")}</Label>
                <Input
                  name="comission"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.comission || ""}
                  disabled={!isEdit}
                  invalid={
                    validation.touched.comission && validation.errors.comission
                      ? true
                      : false
                  }
                />
                {validation.touched.comission && validation.errors.comission ? (
                  <FormFeedback type="invalid">
                    {validation.errors.comission}
                  </FormFeedback>
                ) : null}
              </div> */}
              <div className="mb-3">
                <Label className="form-label">{props.t("Added by")}</Label>
                <Input
                  name="createdBy"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={user?.firstname + " " + user?.lastname}
                  disabled
                  invalid={
                    validation.touched.createdBy && validation.errors.createdBy
                      ? true
                      : false
                  }
                />

                {validation.touched.createdBy && validation.errors.createdBy ? (
                  <FormFeedback type="invalid">
                    {validation.errors.createdBy}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-primary save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(ModalOffice)
