import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import { getMDMBrand, updateMDMBrand } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import BrandModal from "./BrandModal"

import { withTranslation } from "react-i18next"
import MiniCards from "pages/Dashboard/mini-cards"
import { checkPermission } from "helpers/functions"
import moment from "moment"

function Brand(props) {
  //meta title

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [brand, setBrand] = useState(null)
  const [show, setShow] = useState(false)
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
    status: "active",
  })

  const dispatch = useDispatch()

  const {
    brandData,
    error,
    success,
    nextPage,
    currentPage,
    previousPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.brands)

  useEffect(() => {
    dispatch(getMDMBrand(filter))
  }, [dispatch, filter])

  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Total joinings"),
      iconClass: "bx-check-circle",
      text: 0,
    },
    {
      title: props.t("Today joinings"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
  ])
  useEffect(() => {
    if (brandData != undefined) {
      setMiniCards([
        {
          title: props.t("Total joinings"),
          iconClass: "bx-check-circle",
          text: brandData?.filter(item => item.status != "archived").length,
        },
        {
          title: props.t("Today joinings"),
          iconClass: "dripicons-user-group",
          text: brandData?.filter(
            item =>
              moment(item.createdAt).format("YYYY-MM-DD") ==
                moment().format("YYYY-MM-DD") && item.status != "archived"
          ).length,
        },
      ])
    }
  }, [brandData])

  useEffect(() => {
    if (!isEmpty(brandData) && !!isEdit) {
      setIsEdit(false)
    }
  }, [brandData])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setBrand(null)
    } else {
      setModal(true)
    }
  }

  const toggleShow = () => {
    setShow(!show)
  }

  const handleOrderClick = arg => {
    const brand = arg
    setBrand({
      ...brand,
    })

    setIsEdit(true)

    toggle()
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = brand => {
    setBrand(brand)
    setDeleteModal(true)
  }

  const handleDeleteBrand = () => {
    if (brand.id) {
      dispatch(
        updateMDMBrand({
          brand: { ...brand, status: "archived" },
          contract: brand.contract,
        })
      )
      setDeleteModal(false)
      dispatch(getMDMBrand(filter))
    }
  }
  const handleOrderClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Logo"),
        accessor: "name",
        disableFilters: true,
        accessor: cellProps => (
          <>
            {!cellProps.imageUrl ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.name ? cellProps.name.charAt(0) : "0"}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.imageUrl}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: props.t("Name"),
        accessor: "name",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div
              className="external-event"
              onClick={() => {
                setBrand(cellProps)
                toggleShow()
              }}
            >
              {cellProps.name}
            </div>
          )
        },
      },

      {
        Header: props.t("N. Of Stores"),
        accessor: "nbStores",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.nbStores ?? 0}</span>
        },
      },

      {
        Header: props.t("Join Date"),
        accessor: "nbStores",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>{moment(cellProps.createdAt).format("MMM DD . YYYY")}</span>
          )
        },
      },

      {
        Header: props.t("Category"),
        accessor: "category",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.category?.nameEn || "----"}</span>
        },
      },

      {
        Header: props.t("Status"),
        accessor: "status",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span className="text-sucess">
              {cellProps.status || "inactive"}
            </span>
          )
        },
      },

      {
        Header: props.t("Action"),
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {/* {checkPermission(userRoles, [
                "MasterData",
                "Brand",
                "Update",
              ]) && ( */}
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const orderData = cellProps.row.original
                  handleOrderClick(orderData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              {/* )} */}
              {/* {checkPermission(userRoles, ["MasterData", "Brand", "Delete"]) && */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const brandData = cellProps.row.original
                  onClickDelete(brandData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
              {/* } */}
            </div>
          )
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <BrandModal modal={modal} toggle={toggle} isEdit={isEdit} brand={brand} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBrand}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("MDM")}
            breadcrumbItem={props.t("Brand List")}
          />
          <Row>
            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={brandData}
                    //isGlobalFilter={true}
                    isAddOptions={true}
                    //isAddOptions={checkPermission(userRoles, ["MasterData", "Brand", "Create"])}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Brand.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Brand)
