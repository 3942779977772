import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createCardFailed,
  createCardSuccess,
  deleteCardFailed,
  deleteCardSuccess,
  getCardFailed,
  getCardSuccess,
  updateCardFailed,
  resetCardMessagesSuccess,
} from "./actions"
import {
  CARD_MESSAGES_RESET,
  CREATE_CARD_REQUEST,
  DELETE_CARD_REQUEST,
  GET_CARD_BY_NAME_REQUEST,
  GET_CARD_REQUEST,
  RESET_CARD_MESSAGES,
  UPDATE_CARD_REQUEST,
} from "./actionTypes"

import { getCard, postCard, putCard } from "helpers/api_helper"

function* getCardSaga({ payload }) {
  try {
    const response = yield getCard(
      payload || { page: 1, limit: 100, status: "active" }
    )
    yield put(getCardSuccess(response))
  } catch (e) {
    yield put(getCardFailed(e))
  }
}

function* getCardBynameSaga() {}

function* createCardSaga({ payload: card }) {
  try {
    const cardData = yield postCard(card)

    const response = yield getCard({ limit: 100, page: 1, status: "active" })
    yield put(getCardSuccess(response))
  } catch (e) {
    yield put(createCardFailed(e))
  }
}

function* deleteCardSaga({ payload }) {
  try {
    console.log("delee", payload)
    // const{id, ...updatedid}= idcategory
    // console.log("delete",idcategory, updatedid  )
    // console.log("delete category",id   )
    // const response = yield deleteCategories(id);
    //yield put(deleteCardSuccess(id))
  } catch (e) {
    yield put(deleteCardFailed(e))
  }
}

function* updateCardSaga({ payload: card }) {
  try {
    // separer l id & objet category
    const { id, ...updatedCard } = card
    const data = yield putCard(id, updatedCard)
    const response = yield getCard({ limit: 100, page: 1, status: "active" })
    yield put(getCardSuccess(response))
  } catch (e) {
    yield put(updateCardFailed(e))
  }
}

function* resetCardMessagesSaga() {
  yield put(resetCardMessagesSuccess())
}

export default function* cardSaga() {
  yield all([
    takeEvery(GET_CARD_REQUEST, getCardSaga),
    takeEvery(GET_CARD_BY_NAME_REQUEST, getCardBynameSaga),
    takeEvery(CREATE_CARD_REQUEST, createCardSaga),
    takeEvery(DELETE_CARD_REQUEST, deleteCardSaga),
    takeEvery(RESET_CARD_MESSAGES, resetCardMessagesSaga),
    takeEvery(UPDATE_CARD_REQUEST, updateCardSaga),
  ])
}
