import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"
import Cards from "../pages/Cards/Cards"

//Offices
import Office from "../pages/Offices/Office"

//MDM
import Category from "../pages/MDM/Category/Category"
import SubCategory from "../pages/MDM/subCategory/SubCategory"
import Countries from "../pages/MDM/Countries/Country"
import Cities from "../pages/MDM/Cities/Cities"
import governates from "../pages/MDM/Governates/governates"
import settings from "../pages/MDM/Setting/Setting"

//Retailers
import Retailers from "../pages/Retailers/Retailer"
import StatsRretailer from "../pages/Retailers/StatsRetailer/StatsRretailer"

//Transactions
import Transactions from "../pages/Transactions/Transactions"
import TransactionIncom from "../pages/Transactions/transactionIncome/TransactionIncom"
import TransactionsPartner from "../pages/Transactions/partnerIncome/PartnerTransactionsIncome"
//Accounting
import Payout from "../pages/Accounting/Payout/Payout"
import PayIn from "../pages/Accounting/Payin/PayIn"
import Ewallet from "../pages/Ewallet/Ewallet"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ResetPasswordEmail from "../pages/Authentication/ResetPasswordEmail"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Admins
import AdminsGrid from "../pages/Admins/users-grid"
import AdminsList from "../pages/Admins/UserList/users-list"
import AdminsProfile from "../pages/Admins/UsersProfile/user-profile"
import RetailerProfile from "../pages/Retailers/RetailersProfile/retailer-profile"
import PartnerProfile from "../pages/BusinessPartner/BuisnessPartnerProfile/partner-profile"

//Users
import UsersGrid from "../pages/Users/users-grid"
import UsersList from "../pages/Users/UserList/users-list"
import UsersProfile from "../pages/Users/UsersProfile/user-profile"

//Business Partner
import BusinessPartner from "pages/BusinessPartner/Partner/BusinessPartner"
import BusinessPartnerStats from "pages/BusinessPartner/Stats/BusinessPartnerStats"
import Service from "pages/MDM/Service/Service"
import brands from "pages/MDM/Brand/Brand"
import paymentMethod from "pages/MDM/PaymentMethod/PaymentMethod"
import RetailerUsers from "pages/RetailerUsers/UserList/users-list"
import parrainageType from "pages/MDM/ParrainageType/ParrainageType"
import cardTypes from "pages/MDM/CardType/CardType"

//Comission
import ComissionIn from "pages/Retailers/ComissionIn/ComissionIN"

//log
import Logs from "pages/Logs/Logs"
import ResetPassword from "pages/Authentication/ResetPassword"
import RolesList from "pages/Roles/roles-list"
import RolesAddEdit from "pages/Roles/role-add-edit"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //calendar
  { path: "/calendar", component: Calendar },

  //Accounting
  { path: "/payout", component: Payout },
  { path: "/payin", component: PayIn },
  //Ewallet
  { path: "/e-wallet", component: Ewallet },
  //MDM
  { path: "/categories-list", component: Category },
  { path: "/subCategories-list", component: SubCategory },
  { path: "/services-list", component: Service },
  { path: "/countries-list", component: Countries },
  { path: "/cities-list", component: Cities },
  { path: "/governorates-list", component: governates },
  { path: "/setting", component: settings },
  { path: "/retailers-list", component: brands },
  { path: "/retailers-users", component: RetailerUsers },
  { path: "/payment-method", component: paymentMethod },
  { path: "/parrainage-types", component: parrainageType },
  { path: "/card-types", component: cardTypes },
  //Comission
  { path: "/comissionIN", component: ComissionIn },

  //Offices
  { path: "/offices-list", component: Office },

  //Retailers
  { path: "/store-list", component: Retailers },
  { path: "/retailerStats-list", component: StatsRretailer },
  { path: "/businessPartners-list", component: BusinessPartner },
  { path: "/businessPartners-stats", component: BusinessPartnerStats },
  { path: "/cards-list", component: Cards },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/partner-profile/:id", component: PartnerProfile },

  //Transactions
  { path: "/transactions-list", component: Transactions },
  // { path: "/transactions-income", component: TransactionIncom },
  // { path: "/partner-transactions-income", component: TransactionsPartner },

  //Logs
  { path: "/logs", component: Logs },

  // Admins
  { path: "/admins-grid", component: AdminsGrid },
  { path: "/admins-list", component: AdminsList },
  { path: "/admins-profile/:id", component: AdminsProfile },
  { path: "/retailer-profile/:id", component: RetailerProfile },

  // Users
  { path: "/users-grid", component: UsersGrid },
  { path: "/users-list", component: UsersList },
  { path: "/users-profile/:id", component: UsersProfile },

  //Roles
  { path: "/roles-list", component: RolesList },
  { path: "/roles", component: RolesAddEdit },
  { path: "/roles/:id", component: RolesAddEdit },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ResetPasswordEmail },
  { path: "/reset-password/:token/:id", component: ResetPassword },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
