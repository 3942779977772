import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { checkPermission } from "helpers/functions"
import { useSelector } from "react-redux"

//images
import admin from "../../assets/images/admin.svg"
import roles from "../../assets/images/roles.svg"
import cards from "../../assets/images/cards.svg"
import user from "../../assets/images/user.svg"
import office from "../../assets/images/office.svg"
import transaction from "../../assets/images/transaction.svg"

const SidebarContent = props => {
  const ref = useRef()
  const userRoles = useSelector(state => state.Login.user?.roles)

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const [isMenuOpenRetailer, setIsMenuOpenRetailer] = useState(false)
  const [isMenuOpenData, setIsMenuOpenData] = useState(false)

  const toggleMenuData = () => {
    setIsMenuOpenData(!isMenuOpenData)
  }
  const toggleMenuRetailer = () => {
    setIsMenuOpenRetailer(!isMenuOpenRetailer)
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled mt-20" id="side-menu">
            <li>
              <Link
                to="/admins-list"
                className="d-flex align-items-center gap-3"
              >
                <img src={admin} alt="" height="24" />
                <span className="font-size-16">{props.t("Admin")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/roles-list"
                className="d-flex align-items-center gap-3"
              >
                <img src={roles} alt="" height="24" />
                <span className="font-size-16">
                  {props.t("Roles Management")}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/#"
                onClick={e => {
                  e.preventDefault()
                  toggleMenuRetailer()
                }}
                className="w-900"
              >
                <div className=" flex items-center gap-3">
                  <div className="flex items-center gap-3">
                    <i className="bx bx-store"></i>
                    <span className="font-size-16 ">
                      {props.t("Retailer Managment")}
                    </span>
                    <i
                      className={`bx transition-transform duration-300 ${
                        isMenuOpenRetailer
                          ? "bx-chevron-down"
                          : "bx-chevron-right"
                      }`}
                    ></i>
                  </div>
                </div>
              </Link>
              <ul className={`sub-menu `}>
                <li>
                  <Link to="/retailers-list">{props.t("Retailers List")}</Link>
                </li>
                <li>
                  <Link to="/store-list">{props.t("Stores List")}</Link>
                </li>
                <li>
                  <Link to="/retailers-users">{props.t("Retailer Users")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/users-list"
                className="d-flex align-items-center gap-3"
              >
                <img src={user} alt="" height="24" />
                <span className="font-size-16">
                  {props.t("Customer Management")}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/cards-list"
                className="d-flex align-items-center gap-3"
              >
                <img src={cards} alt="" height="24" />
                <span className="font-size-16">{props.t("List Of Cards")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/offices-list"
                className="d-flex align-items-center gap-3"
              >
                <img src={office} alt="" height="24" />
                <span className="font-size-16">
                  {props.t("Almazaya Offices")}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/transactions-list"
                className="d-flex align-items-center gap-3"
              >
                <img src={transaction} alt="" height="24" />
                <span className="font-size-16">{props.t("Transactions")}</span>
              </Link>
            </li>
            {/* {(checkPermission(userRoles, ["MasterData", "Brand", "List"]) ||
              checkPermission(userRoles, [
                "MasterData",
                "PaymentMethod",
                "List",
              ]) ||
              checkPermission(userRoles, [
                "MasterData",
                "LevelCommission",
                "List",
              ]) ||
              checkPermission(userRoles, ["MasterData", "Category", "List"]) ||
              checkPermission(userRoles, ["MasterData", "Country", "List"]) ||
              checkPermission(userRoles, [
                "MasterData",
                "Governorate",
                "List",
              ]) ||
              checkPermission(userRoles, ["MasterData", "City", "List"]) ||
              checkPermission(userRoles, [
                "MasterData",
                "Settings",
                "List",
              ])) && ( */}
            <li>
              <Link
                onClick={e => {
                  e.preventDefault()
                  toggleMenuData()
                }}
                to="/#"
              >
                <i className="bx bx-data font-size-22"></i>
                <span className="font-size-16">{props.t("Master Data")}</span>
                <i
                  className={`bx transition-transform duration-300 ${
                    isMenuOpenData ? "bx-chevron-down" : "bx-chevron-right"
                  }`}
                ></i>
              </Link>

              <ul className="sub-menu">
                {/* {checkPermission(userRoles, [
                    "MasterData",
                    "Brand",
                    "List",
                  ]) && ( */}

                {/* )} */}
                <li>
                  <Link to="/card-types">{props.t("Card Types")}</Link>
                </li>
                <li>
                  <Link to="/payment-method">{props.t("Payment Method")}</Link>
                </li>
                <li>
                  <Link to="/parrainage-types">
                    {props.t("Parrainage Types")}
                  </Link>
                </li>
                {checkPermission(userRoles, [
                  "MasterData",
                  "LevelCommission",
                  "List",
                ]) && (
                  <li>
                    <Link to="/level-comission-list">
                      {props.t("Level Commission list")}
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/categories-list">
                    {props.t("Categories List")}
                  </Link>
                </li>
                <li>
                  <Link to="/subCategories-list">
                    {props.t("Sub-Categories List")}
                  </Link>
                </li>
                <li>
                  <Link to="/countries-list">{props.t("Countries List")}</Link>
                </li>

                <li>
                  <Link to="/governorates-list">
                    {props.t("Governorates List")}
                  </Link>
                </li>
                <li>
                  <Link to="/cities-list">{props.t("Cities List")}</Link>
                </li>
                <li>
                  <Link to="/services-list">{props.t("Services List")}</Link>
                </li>
                {checkPermission(userRoles, [
                  "MasterData",
                  "Settings",
                  "List",
                ]) && (
                  <li>
                    <Link to="/setting">{props.t("Settings")}</Link>
                  </li>
                )}
              </ul>
            </li>
            {/* {(checkPermission(userRoles, ["Accounting", "Payout", "List"]) ||
              checkPermission(userRoles, ["Accounting", "Payin", "List"])) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="fas fa-money-bill-alt"></i>
                  <span>{props.t("Accounting")}</span>
                </Link>
                <ul>
                  {checkPermission(userRoles, [
                    "Accounting",
                    "Payout",
                    "List",
                  ]) && (
                    <li>
                      <Link to="/payout">{props.t("Payout")}</Link>
                    </li>
                  )}
                  {checkPermission(userRoles, [
                    "Accounting",
                    "Payin",
                    "List",
                  ]) && (
                    <li>
                      <Link to="/payin">{props.t("PayIn")}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )} */}
            {/* {(checkPermission(userRoles, ["Ewallet", "Transaction", "List"]) ||
              checkPermission(userRoles, ["Ewallet", "Balance", "List"]) ||
              checkPermission(userRoles, ["Ewallet", "Statement", "List"]) ||
              checkPermission(userRoles, [
                "Ewallet",
                "UserEarning",
                "List",
              ])) && (
              <li>
                <Link to="/e-wallet">
                  <i className="bx bxs-briefcase"></i>

                  <span>{props.t("Ewallet")}</span>
                </Link>
              </li>
            )} */}
            {/* {(checkPermission(userRoles, [
              "Transaction",
              "Transaction",
              "List",
            ]) ||
              checkPermission(userRoles, [
                "Transaction",
                "PartnerIncome",
                "List",
              ]) ||
              checkPermission(userRoles, [
                "Transaction",
                "TransactionIncome",
                "List",
              ])) && ( */}
            {/* <li> */}
            {/* <Link to="/#" className="has-arrow">
                <i className="bx bx-transfer"></i>
                <span>{props.t("Transactions")}</span>
              </Link> */}
            {/* <ul>
                  {checkPermission(userRoles, [
                    "Transaction",
                    "Transaction",
                    "List",
                  ]) && (
                    <li>
                      <Link to="/transactions-list">
                        {props.t("Transactions List")}
                      </Link>
                    </li>
                  )}
                  {checkPermission(userRoles, [
                    "Transaction",
                    "PartnerIncome",
                    "List",
                  ]) && (
                    <li>
                      <Link to="/partner-transactions-income">
                        {props.t("Partner Transactions Income")}
                      </Link>
                    </li>
                  )}
                  {checkPermission(userRoles, [
                    "Transaction",
                    "TransactionIncome",
                    "List",
                  ]) && (
                    <li>
                      <Link to="/transactions-income">
                        {props.t("Transactions Income")}
                      </Link>
                    </li>
                  )}
                </ul> */}
            {/* </li> */}
            {/* )} */}
            {/* {checkPermission(userRoles, ["Audit", "Log", "List"]) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Audit")}</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/logs">{props.t("Logs")}</Link>
                  </li>
                </ul>
              </li>
            )} */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
