import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMParrainageFailed,
  createMDMParrainageSuccess,
  deleteMDMParrainageFailed,
  deleteMDMParrainageSuccess,
  getMDMParrainageFailed,
  getMDMParrainageSuccess,
  updateMDMParrainageFailed,
  updateMDMParrainageSuccess,
} from "./actions"
import {
  PARRAINAGE_MESSAGES_RESET,
  CREATE_PARRAINAGE_REQUEST,
  DELETE_PARRAINAGE_REQUEST,
  GET_PARRAINAGE_BY_NAME_REQUEST,
  GET_PARRAINAGE_REQUEST,
  RESET_PARRAINAGE_MESSAGES,
  UPDATE_PARRAINAGE_REQUEST
} from "./actionTypes";



import { getParrainage, postParrainage, putParrainage } from "helpers/api_helper";



function* getParrainageSaga({payload}) {
  try {
    // console.log("payload pay",payload)
    const response = yield getParrainage(payload || {page: 1, limit: 1000});
    yield put(getMDMParrainageSuccess(response))
  } catch (e) {
    yield put(getMDMParrainageFailed(e))
  }
}

function* getParrainageBynameSaga() {

}



function* createParrainageSaga({ payload: parrainage }) {
  try {
    const data = yield postParrainage(parrainage);
    const response = yield getParrainage({limit: 20, page: 1});
    yield put(getMDMParrainageSuccess(response))

  } catch (e) {
    yield put(createMDMParrainageFailed(e))
  }
}

function* deleteParrainageSaga({ payload: id }) {
  try {
    // const result = yield new Repository().deleteCategory(payload)
    // const data = yield new Repository().getCategories({ current: 1, limit: 20 })
    yield put(deleteMDMParrainageSuccess(id))
  } catch (e) {
    yield put(deleteMDMParrainageFailed(e))
  }
}

function* updateParrainageSaga({ payload: parrainage }) {
  try {
    // separer l id & objet parrainage
    const { id, ...updatedparrainage } = parrainage

    const data = yield putParrainage(id, updatedparrainage);
    const response = yield getParrainage({limit: 20, page: 1});
    yield put(getMDMParrainageSuccess(response))
  } catch (e) {
    yield put(updateMDMParrainageFailed(e))
  }
}

function* resetParrainageMessagesSaga() {
  yield put(resetParrainageMessagesSuccess())
}




export default function* parrainageSaga() {
  yield all([
    takeEvery(GET_PARRAINAGE_REQUEST, getParrainageSaga),
    takeEvery(GET_PARRAINAGE_BY_NAME_REQUEST, getParrainageBynameSaga),
    takeEvery(CREATE_PARRAINAGE_REQUEST, createParrainageSaga),
    takeEvery(DELETE_PARRAINAGE_REQUEST, deleteParrainageSaga),
    takeEvery(UPDATE_PARRAINAGE_REQUEST, updateParrainageSaga),
    takeEvery(RESET_PARRAINAGE_MESSAGES, resetParrainageMessagesSaga),
  ])
}
