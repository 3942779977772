import React, { useEffect, useState } from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"

//redux
import { useDispatch, useSelector } from "react-redux"

import { createMDMCity, getMDMGovernorates, updateMDMCity } from "store/actions"

const CityModal = props => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const dispatch = useDispatch()
  const { modal, toggle, City, isEdit } = props
  const { governoratesData } = useSelector(state => state.governorates)
  useEffect(() => {
    {
      dispatch(getMDMGovernorates())
    }
  }, [dispatch, filter])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nameEn: (City && City.nameEn) || "",
      nameFr: (City && City.nameFr) || "",
      nameAr: (City && City.nameAr) || "",
      parentId: (City && City.parentId) || "",
      type: (City && City.type) || "city",
    },
    validationSchema: Yup.object({
      nameEn: Yup.string().required(props.t("Please Enter Your English name")),
      // nameFr: Yup.string().required(props.t("Please Enter Your French name")),
      // nameAr: Yup.string().required(props.t("Please Enter Your Arabic Name")),
      parentId: Yup.number().required(props.t("Please Enter Your Country")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateCity = {
          id: City ? City.id : 0,
          nameEn: values.nameEn,
          nameFr: values.nameFr,
          nameAr: values.nameAr,
          parentId: values.parentId,
        }
        // update
        // console.log("updatecity",updateCity)
        dispatch(updateMDMCity(updateCity))
        validation.resetForm()
      } else {
        const newCity = {
          nameEn: values["nameEn"],
          nameFr: values["nameFr"],
          nameAr: values["nameAr"],
          parentId: values["parentId"],
          type: values["type"],
        }
        // save new city
        dispatch(createMDMCity(newCity))
        validation.resetForm()
      }
      toggle()
    },
  })

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit City") : props.t("Add City")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="nameEn"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameEn || ""}
                  invalid={
                    validation.touched.nameEn && validation.errors.nameEn
                      ? true
                      : false
                  }
                />
                {validation.touched.nameEn && validation.errors.nameEn ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameEn}
                  </FormFeedback>
                ) : null}
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">{props.t("French Name")}</Label>
                <Input
                  name="nameFr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameFr || ""}
                  invalid={
                    validation.touched.nameFr && validation.errors.nameFr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameFr && validation.errors.nameFr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameFr}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Arabic Name")}</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div> */}
              <div className="mb-3">
                <Label className="form-label">{props.t("Governorate")}</Label>
                <Input
                  type="select"
                  name="parentId"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.parentId || []}
                  invalid={
                    validation.touched.parentId && validation.errors.parentId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {governoratesData
                    .filter(country => country.status != "archived")
                    .map(item => (
                      <option key={item.id} value={item.id}>
                        {item.nameAr}
                      </option>
                    ))}
                </Input>
                {validation.touched.parentId && validation.errors.parentId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.parentId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-customer">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(CityModal)
