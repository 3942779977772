import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { isEmpty, map } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import moment from "moment"
import {
  getRetailers,
  resetRetailersMessages,
  updateRetailer,
  getMDMCategories,
  getMDMBrand,
  getMDMCities,
  getRetailersStats,
  getOffices,
  resetOfficesMessages,
  updateOffice,
} from "store/actions"
import ModalOffice from "./ModalOffice"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Button,
} from "reactstrap"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import MiniCards from "pages/Dashboard/mini-cards"
import { checkPermission } from "helpers/functions"

function Office(props) {
  //meta title

  const history = useHistory()
  const [hiddenColumn, setHiddenColumn] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")

  const [retailer, setRetailer] = useState(null)

  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")

  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    address: "",
    cityId: "",
    from: "",
    to: "",
    status: "active",
    page: 1,
    limit: 100,
  })
  const {
    officesData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.offices)
  const userRoles = useSelector(state => state.Login.user?.roles)

  const { retailersStats } = useSelector(state => state.retailers)
  const { categoriesData } = useSelector(state => state.categories)
  const { citiesData } = useSelector(state => state.cities)
  const { brandData } = useSelector(state => state.brands)
  const componentRef = useRef()
  useEffect(() => {
    dispatch(getOffices(filter))
  }, [dispatch, filter])

  useEffect(() => {
    // dispatch(getRetailersStats())
    // dispatch(getMDMCategories())
    // dispatch(getMDMBrand())
    // dispatch(getMDMCities())
    // dispatch(getRetailersByName(filter.name))
  }, [dispatch, filter])

  useEffect(() => {
    if (!isEmpty(officesData) && !!isEdit) {
      setIsEdit(false)
    }
  }, [officesData])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setRetailer(null)
    } else {
      setModal(true)
    }
  }
  const handleOrderClick = arg => {
    const retailer = arg
    setRetailer({
      ...retailer,
    })

    setIsEdit(true)

    toggle()
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = retailer => {
    setRetailer(retailer)
    setDeleteModal(true)
  }

  const handleDeleteRetailer = () => {
    if (retailer.id) {
      dispatch(updateOffice({ id: retailer.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getOffices(filter))
    }
  }
  const handleRetailerClicks = () => {
    setRetailer()
    setIsEdit(false)
    toggle()
  }

  useEffect(() => {
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Retailer created successfully !")
      dispatch(resetOfficesMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("successfully !")
      dispatch(resetOfficesMessages())
    }
  }, [success])

  console.log("officesData", officesData)

  const columns = useMemo(
    () => [
      {
        Header: props.t("Logo"),
        accessor: "imageUrl",
        disableFilters: true,
        accessor: cellProps => (
          <>
            {!cellProps.imageUrl ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.name ? cellProps.name.charAt(0) : "-"}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.imageUrl}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },

      {
        Header: props.t("Name"),
        accessor: "name",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div className="external-event">
              {cellProps.name || props.t("Not provided")}
            </div>
          )
        },
      },
      {
        Header: props.t("Email"),
        accessor: "Email",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div className="external-event">
              {cellProps.email || props.t("Not provided")}
            </div>
          )
        },
      },
      {
        Header: props.t("Phone"),
        accessor: "phone",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div className="external-event">
              {cellProps.phone || props.t("Not provided")}
            </div>
          )
        },
      },
      {
        Header: props.t("Address"),
        accessor: "address",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div className="external-event">
              {cellProps.address || props.t("Not provided")}
            </div>
          )
        },
      },
      {
        Header: props.t("Country"),
        accessor: "country",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.country
                ? (currentLanguage == "ar" && cellProps.country?.nameAr) ||
                  (currentLanguage == "en" && cellProps.country?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.country?.nameFr)
                : props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("City"),
        accessor: "city",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.city
                ? (currentLanguage == "ar" && cellProps.city?.nameAr) ||
                  (currentLanguage == "en" && cellProps.city?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.city?.nameFr)
                : props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("State"),
        accessor: "state",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.state
                ? (currentLanguage == "ar" && cellProps.state?.nameAr) ||
                  (currentLanguage == "en" && cellProps.state?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.state?.nameFr)
                : props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.status || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Action"),
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {checkPermission(userRoles, [
                "Retailer",
                "Retailer",
                "Update",
              ]) && (
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const orderData = cellProps.row.original
                    handleOrderClick(orderData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {props.t("Edit")}
                  </UncontrolledTooltip>
                </Link>
              )}
              {checkPermission(userRoles, [
                "Retailer",
                "Retailer",
                "Delete",
              ]) && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const orderData = cellProps.row.original
                    onClickDelete(orderData)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {props.t("Delete")}
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }
  const setCategory = category => {
    setFilter(prevFilter => {
      return { ...prevFilter, categoryId: category.value }
    })
  }

  const setShopname = name => {
    setFilter(prevFilter => {
      return { ...prevFilter, shopName: name.label }
    })
  }

  const setCity = city => {
    setFilter(prevFilter => {
      return { ...prevFilter, cityId: city.value }
    })
  }
  const setBrand = brand => {
    setFilter(prevFilter => {
      return { ...prevFilter, brandId: brand.value }
    })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRetailer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Offices")}
            breadcrumbItem={props.t("Offices List")}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={officesData?.filter(
                      offices => offices.status != "archived"
                    )}
                    // userRef={componentRef}
                    // isPrintOption={checkPermission(userRoles, ["Retailer", "Retailer", "Print"])}

                    // shopnameFilter={true}
                    // setHiddenColumn={setHiddenColumn}
                    // setShopname={setShopname}
                    retailers={officesData}
                    // resetOption={true}
                    // categoryFilter={true}
                    categories={categoriesData}
                    setCategory={setCategory}
                    // cityFilter={true}
                    cities={citiesData}
                    setCity={setCity}
                    // brandFilter={true}
                    brands={brandData}
                    setBrand={setBrand}
                    handleOrderClicks={handleRetailerClicks}
                    isAddOfficeOptions={true}
                    //{checkPermission(userRoles, ["Retailer", "Retailer", "Create"])}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    // setFilter={doSearch}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />

                  <ModalOffice
                    modal={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    retailer={retailer}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Office.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Office)
